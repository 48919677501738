.inline-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  li {
    margin-left: 35px;
    &:first-child {
      margin-left: 0;
    }
  }
}
