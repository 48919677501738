body {
    position: relative;
    &:before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: url('../dist/img/baba-plant.png') repeat;
        background-size: 20%;
        opacity: 0.5;
    }
}

footer {
    margin-top: 20px;
    padding: 15px 0;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 32px);
        height: 1px;
        background: $black;
    }


    p {
        font-weight: bold;
        font-size: 11px;
        text-align: center;
    }
}

.is-visible-tablet {
    display: none;
    @media only screen and (min-width: 650px) {
        display: inline-block;
    }
}

.site-title {
    margin-top: 10px;
    font-size: $size-2;
    text-align: center; 
    @media only screen and (max-width: 374px) {
        font-size: $size-3;
    }
}

.site-subtitle {
    margin-bottom: 20px;
    font-size: $size-5;
    text-align: center; 
}

header {
    box-shadow: 2px 2px 40px rgba($black, 0.2);
    padding: 10px 0;
    position: sticky;
    top: 0;
    z-index: 3;
    background: $white;
    height: 73px;
}

.menu-cards-wrapper {
    .is-6 {
        &:nth-child(1), &:nth-child(4) {
            padding-right: 12px;
        }
    }
    .is-6 {
        &:nth-child(2), &:nth-child(5) {
            padding-left: 12px;
        }
    }
}

.menu-card {
    position: relative;
    margin-bottom: 25px;
    padding: 20px 5px;
    background: $white;
    border: 1px solid $black;
    border-radius: 6px;
    text-align: center;
}

.menu-card-link {
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
    i, span {
        display: block;
    }
    span {
        font-size: $size-7;
        margin-top: 10px;
        font-weight: 500;
        @media only screen and (max-width: 374px) {
            font-size: $size-8;
        }
    }
}

.baba-events {
    .contact-title {
        margin-top: 20px;
        padding-top: 20px;
        font-size: $size-5;
    }
}

.lb-nav a.lb-prev,
.lb-nav a.lb-next {
    opacity: 1;
}

.lightbox .lb-image {
    border: none;
}

.lb-dataContainer {
    position: absolute;
    top: -38px;
    right: 10px;
}

.lb-data .lb-details {
    display: none;
}

.info {
    .follow-title, .evaluation-title, .contact-title {
        margin-top: 20px;
        padding-top: 20px;
        font-size: $size-5;
    }

    .social-link, .map-link, .phone {
        font-size: 20px;
        font-weight: 500;
    }

    .social-link {
        background: $white;
    }
}

.tripadvisor {
    &:hover {
        img {
            filter: brightness(10) invert(1);
        }
    }
}

.menu-info {
    position: relative;
    padding-top: 20px;
    
    &:before {
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        background: $black;
    }

    p {
        font-size: $size-8;
    }
}