.button {
  height: auto;
  overflow: hidden;
  border: 1px solid $black;
  background: transparent;
  color: $black;
  &:hover {
    text-decoration: none !important;
    background: $black;
    color: $white;

  }
}

.info-trigger {
  cursor: pointer;
  background: $white;
  border: 1px solid $black;
  border-radius: 6px;
  padding: 10px 14px;
  i {
    display: block;
  }
  span {
    display: block;
    @media only screen and (max-width: 370px) {
      font-size: 11px;
    }
  }
}