.menu-categories-dropdown-wrapper {
    padding: 16px 0;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    position: sticky;
    top: 72px;
    background: $white;
    cursor: pointer;
    z-index: 3;
}

.selected-item {
    padding: 0 16px;
    font-weight: bold;
    font-size: $size-4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile {
        font-size: $size-6;
    }

    > span {
        line-height: 1;
        span {
            margin-left: 5px;
            @media only screen and (max-width: 374px) {
                font-size: $size-8;
            }
        }
    }

    &.opened {
        .fa-bars {
            display: none;
        }
        .fa-times {
            display: block;
        }
    }
}

.fa-times {
    display: none;
}

.menu-categories {
    position: relative;
}

.has-dropdown {
    display: none !important;
    opacity: 0;
    margin-left: 0;
    position: absolute;
    right: 0;
    left: 0;
    top: 37px;
    width: 100%;
    padding: 16px;
    background: #fff;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.15);
    overflow-y: scroll;
    height: calc(100vh - 125px);
    &.is-active {
        z-index: 99;
        opacity: 1;
        display: block !important;
        transition: all 0.5s ease-in-out;
      }


      ul {
        display: block;
        margin-left: 0;
        position: relative;
        li {
            margin-bottom: 18px;
            display: block;
            font-size: $size-6;
            &.is-chosen {
                font-weight: bold;
            }
            
            &.others {
                padding-top: 20px;
                border-top: 1px solid $black;
            }

            &.category-title {
                font-size: $size-5;
                font-weight: bold;
                a {
                  font-size: $size-6;
                  font-weight: bold;
                  svg {
                      margin-right: 5px;
                  }
                }
            }
          }
      }
      
  }


  .menu-items-wrapper {

      li {
          margin-bottom: 20px;
      }


      h3 {
          position: sticky;
          top: 125px;
          z-index: 2;
          margin-left: -16px;
          margin-right: -16px;
          margin-bottom: 0;
          padding: 16px;
          margin-bottom: 20px;
          background: $white;
          font-size: $size-5;
          font-weight: bold;
          @media only screen and (max-width: 374px) {
              font-size: $size-6;
          }
      }


      h4 {
          position: relative;
          margin-bottom: 5px;
          display: flex;
          justify-content: space-between;
          font-size: $size-6;
          font-weight: 500;
          @media only screen and (max-width: 374px) {
            font-size: $size-7;
        }
          + p {
              color: rgba($black, 0.5);
            font-weight: 300;
          }
          span:not(.price) {
            @include mobile {
                width: 53%;
            }
          }
            .sizes {
                right: 0;
            }
      }

      p {
          font-style: italic;
          font-size: 18px;
      }


      .price {
          margin-left: 10px;
          font-weight: 500;
      }
  }

  .shot .fa-sm {
      font-size: 13px;
  }

  .glass .fa-lg {
      font-size: 20px;
  }

  .bottle .fa-lg {
      font-size: 25px;
      transform: rotate(-45deg);
  }

  .sizes {
    display: flex;
    position: absolute;
    right: 16px;
    top: 50%;
    width: 190px;
    transform: translateY(-50%);
    justify-content: flex-end;

    li {
        min-width: 38px;
        margin: 0;
        text-align: center;
        &:not(:last-child) {
            margin-right: 38px;
            @include mobile {
                margin-right: 15px;
            }
        }
    }
}


.other-categories {
    margin-left: 15px !important;
    padding-left: 15px;
    border-left: 1px solid rgba($black, 0.5);
    span {
        margin-left: 15px;
    }
}