.container {
  &.is-fluid {
    padding: 0;
  }
}

.fixed {
  position: sticky !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  z-index: 99 !important;
  animation: slide-down 0.3s;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

*:hover {
  transition: all 0.3s ease-in-out;
}

.columns:last-child {
  margin-bottom: 0;
}

.no-padding-mobile {
  @include mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.no-padding-left-desktop {
  padding-left: 0 !important;
  @include mobile {
    padding-left: 16px !important;
  }
}

.no-padding-left {
  padding-left: 0 !important;
  @include mobile {
    padding-left: 0 !important;
  }
}

.no-padding-left-mobile {
  @include mobile {
    padding-left: 0 !important;
  }
}

.no-padding-right-desktop {
  padding-right: 0 !important;
  @include mobile {
    padding-right: 16px !important;
  }
}

.no-padding-right {
  padding-right: 0 !important;
  @include mobile {
    padding-right: 0 !important;
  }
}

.no-padding-right-mobile {
  @include mobile {
    padding-right: 0 !important;
  }
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
  @include mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.columns {
  @include tablet {
    margin: 0;
  }

  @include mobile {
    margin: 0;
  }

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.column {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
}

.is-used {
  overflow: hidden;
}

.is-visible-mobile-only {
  display: none !important;
  @include mobile {
    display: block !important;
  }
}

.ordered-mobile {
  @media only screen and (max-width: 980px) {
    display: flex;
    flex-flow: column;
    align-items: flex-start !important;
  }
}

.order-mobile-1 {
  @media only screen and (max-width: 980px) {
    order: 1;
    width: 100%;
  }
}

.order-mobile-2 {
  @media only screen and (max-width: 980px) {
    order: 2;
    width: 100%;
  }
}

.order-mobile-3 {
  @media only screen and (max-width: 980px) {
    order: 3;
    width: 100%;
  }
}

.is-space-between {
  justify-content: space-between;
}

.is-space-around {
  justify-content: space-around;
}

.is-flex-end {
  justify-content: flex-end;
}

.is-bottom {
  align-items: flex-end;
}

.img-container {
  position: relative;
}
