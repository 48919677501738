@charset "utf-8"

@import '~lightbox2/dist/css/lightbox.min'

//@import 'typography/font'
@import '_variables'

// 2. Import the initial variables
@import '~bulma/sass/utilities/all'

// Reset Styles for all browsers
@import '~bulma/sass/base/_all'
@import '~bulma/sass/helpers/_all'

// Import Specific Elements from BULMA framework
@import '~bulma/sass/elements/button'
@import '~bulma/sass/elements/container'
@import '~bulma/sass/elements/content'
@import '~bulma/sass/elements/other'

//@import '~bulma/sass/form/_all'

//@import '~bulma/sass/components/menu'
//@import '~bulma/sass/components/dropdown'
//@import '~bulma/sass/components/navbar'

@import '~bulma/sass/components/modal'

// Import BULMA GRID SYSTEM
$column-gap: 1rem !default
@import '~bulma/sass/grid/_all'

// Plyr
//$plyr-color-main: $yellow !default
//@import '~plyr/src/sass/plyr'

//@import '~cookieconsent/build/cookieconsent.min.css'

@import 'typography/font'
//@import 'mixins/_mixins'


@import 'atoms/general-elements'
//@import 'atoms/_read-more'
//@import 'atoms/_iframe'
@import 'atoms/_buttons'
@import 'atoms/_lists'

@import 'molecules/_modal'
@import 'molecules/_dropdown'

@import 'organisms/_page'
@import 'organisms/_cocktail-list'
@import 'organisms/_bar-menu'